import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Helmet } from "react-helmet"
import Box from "../components/SDS/Box"
import Container from "../components/SDS/Container"
import Header from "../components/Header"
import { Heading } from "../components/SDS/Typography"
import Sidebar from "../components/Sidebar"
import { MDXComponents } from "../components/MDXComponents"

export default function Layout({ data: { mdx } }) {
  return (
    <Box bg="background.primary">
      <Helmet
        defaultTitle="Soundstripe Design"
        titleTemplate="%s | Soundstripe Design"
      >
        <title>{mdx.frontmatter.title}</title>
        <meta name="docsearch:language" content="en" />
        <meta name="docsearch:version" content="1.0.0" />
      </Helmet>
      <Header />
      <Container maxWidth="container.lg" pt={0} pb={0}>
        <Box display="flex">
          <Sidebar />
          <Box p={8} flex="1">
            <MDXProvider components={MDXComponents}>
              <Heading mb={7} level={1} size="h1">
                {mdx.frontmatter.title}
              </Heading>
              <MDXRenderer frontmatter={mdx.frontmatter}>
                {mdx.body}
              </MDXRenderer>
            </MDXProvider>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

/**
 * Query for data for the page. Note that $id is injected in via context from
 * actions.createPage. See gatsby-node.js for more info.
 */
export const pageQuery = graphql`
  query DocsPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
      }
    }
  }
`
