import * as React from "react"
import Box from "./SDS/Box"
import Link from "./SDS/Link"

const Header = () => {
  return (
    <Box
      as="header"
      display="flex"
      alignItems="center"
      borderBottom={0}
      borderColor="border.default"
      justifyContent="space-between"
      px={7}
      py={4}
    >
      <Link appearance="secondary" noUnderline to="/">
        <Box
          color="text.primary"
          fontSize="md"
          lineHeight="1.5"
          fontWeight="semiBold"
        >
          Soundstripe Design System
        </Box>
      </Link>
      <button onClick={console.log("toggle color mode")}>
        Toggle Dark Mode
      </button>
    </Box>
  )
}

export default Header
