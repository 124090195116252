import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Box from "./SDS/Box"
import Link from "./SDS/Link"

const Container = styled.div`
  border-right: ${({ theme }) => theme.borders[0]}
    ${({ theme }) => theme.colors.border.default};
  bottom: auto;
  flex: 0 0 auto;
  height: 100vh;
  inset: 0;
  margin-top: 0px;
  min-width: 0px;
  padding: ${({ theme }) => theme.space[8]} ${({ theme }) => theme.space[5]} 0;
  overflow: visible auto;
  position: sticky;
  width: 20%;
`

const SidebarLink = styled(Link)`
  border-radius: ${({ theme }) => theme.radii.default};
  color: ${({ theme }) => theme.colors.text.primary};
  display: block;
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
  text-decoration: none;

  &:hover {
    background: ${({ theme }) => theme.colors.background.elevatedLow};
    text-decoration: underline;
  }

  &.isActive {
    background: ${({ theme }) => theme.colors.background.elevatedMid};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }
`

const Sidebar = () => {
  const menuSections = ["Foundation", "Components"]

  const data = useStaticQuery(graphql`
    query {
      allMdx(sort: { fields: frontmatter___title }) {
        nodes {
          frontmatter {
            title
            section
          }
          fields {
            slug
          }
          id
        }
      }
    }
  `)

  return (
    <Container as="nav">
      <Box mb={6}>
        <SidebarLink to="/">Home</SidebarLink>
      </Box>
      {menuSections.map((section, index) => (
        <Box key={index} mb={6}>
          <Box
            color="text.primary"
            fontSize="md"
            lineHeight="1.5"
            fontWeight="semiBold"
            key={index}
            mb={2}
            pl={3}
          >
            {section}
          </Box>
          <ul>
            {data.allMdx.nodes.map((node, id) => {
              if (node.frontmatter.section === section) {
                return (
                  <Box as="li" key={node.id}>
                    <SidebarLink
                      activeClassName="isActive"
                      to={node.fields.slug}
                    >
                      {node.frontmatter.title}
                    </SidebarLink>
                  </Box>
                )
              } else {
                return null
              }
            })}
          </ul>
        </Box>
      ))}
    </Container>
  )
}

export default Sidebar
