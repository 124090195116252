import { css } from "styled-components"

const linkStyles = css`
  align-items: center;
  color: ${(props) => props.theme.colors.text.action};
  cursor: pointer;
  display: inline-flex;
  font-weight: ${(props) => props.theme.fontWeights.medium};
  height: auto;
  text-decoration: underline;
  text-underline-offset: 1px;
  transition: box-shadow ${(props) => props.theme.transitions.quick};

  &.secondary {
    color: ${(props) => props.theme.colors.text.primary};
  }

  &.subtle {
    color: ${(props) => props.theme.colors.text.secondary};
  }

  &:hover {
    color: ${(props) => props.theme.colors.text.action};
    text-decoration: none;
  }

  &:active {
    color: ${(props) => props.theme.colors.text.action};
  }

  &.withIcon,
  &.noUnderline {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:active {
      text-decoration: underline;
    }
  }

  svg {
    &:first-child {
      margin-right: 2px;
    }

    &:last-child {
      margin-left: 2px;
    }
  }
`

export default linkStyles
