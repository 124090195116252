import React from "react"
import styled from "styled-components"
import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live"
import Highlight, { defaultProps } from "prism-react-renderer"
import theme from "prism-react-renderer/themes/nightOwl"
import { useMDXComponents } from "@mdx-js/react"

const Pre = styled.pre`
  border-radius: ${({ theme }) => theme.radii.default};
  padding: ${({ theme }) => theme.space[5]};
  margin: 0 0 ${({ theme }) => theme.space[7]} 0;
  text-align: left;

  &:last-child {
    margin-bottom: ${({ theme }) => theme.space[2]};
  }
`

const StyledPreview = styled(LivePreview)`
  border: ${({ theme }) => theme.borders[0]}
    ${({ theme }) => theme.colors.border.default};
  border-radius: ${({ theme }) => theme.radii.default}
    ${({ theme }) => theme.radii.default} 0 0;
  padding: ${({ theme }) => theme.space[5]};
`

const Code = ({ codeString, language, ...props }) => {
  const components = useMDXComponents()
  if (props["react-live"]) {
    return (
      <LiveProvider code={codeString} theme={theme} scope={components}>
        <StyledPreview />
        <LiveEditor style={{ marginBottom: "32px" }} />
        <LiveError />
      </LiveProvider>
    )
  } else {
    return (
      <Highlight
        {...defaultProps}
        code={codeString}
        language={language}
        theme={theme}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <Pre className={className} style={style}>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </Pre>
        )}
      </Highlight>
    )
  }
}

export default Code
