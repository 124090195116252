import styled from "styled-components"
import { layout, space } from "styled-system"

const Container = styled.div`
  ${layout}
  ${space}
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`

Container.defaultProps = {
  px: { _: 5, md: 6, lg: 7 },
  pb: { _: 5, xs: 6, lg: 7 },
  pt: { _: 5, xs: 6, lg: 7 },
  maxWidth: "container.xl",
}

export default Container
