import React from "react"
import { preToCodeBlock } from "mdx-utils"
import Box from "./SDS/Box"
import Code from "./Code"
import CodeBlock from "./CodeBlock"
import {
  FlexTable,
  FlexTableCell,
  FlexTableHeader,
  FlexTableRow,
} from "./FlexTable"
import { Heading, Text } from "./SDS/Typography"
import Link from "./SDS/Link"

export const MDXComponents = {
  Box,
  Code,
  FlexTable,
  FlexTableCell,
  FlexTableHeader,
  FlexTableRow,
  Heading,
  Text,
  pre: (preProps) => {
    const props = preToCodeBlock(preProps)

    if (props) {
      return <CodeBlock {...props} />
    } else {
      return <pre {...preProps} />
    }
  },
  code: CodeBlock,
  h1: (props) => (
    <Heading level={1} size="h1">
      {props.children}
    </Heading>
  ),
  h2: (props) => (
    <Heading level={2} size="h3" mb={4} mt={9}>
      {props.children}
    </Heading>
  ),
  h3: (props) => (
    <Heading level={3} size="h3">
      {props.children}
    </Heading>
  ),
  h4: (props) => (
    <Heading level={4} size="h4">
      {props.children}
    </Heading>
  ),
  h5: (props) => (
    <Heading level={5} size="h5">
      {props.children}
    </Heading>
  ),
  p: (props) => (
    <Text mb={4} maxWidth="620px">
      {props.children}
    </Text>
  ),
  a: (props) => <Link>{props.children}</Link>,
  div: (props) => {
    return <div className="contentDiv">{props.children}</div>
  },
}
